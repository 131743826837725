import React, { createContext, useState, useContext, useEffect } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut, User, UserCredential } from 'firebase/auth';
import axios from 'axios';
import { auth } from '../firebase'; // この行を追加

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  refreshToken: () => Promise<string | null>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user: User | null) => {
      if (user) {
        setUser(user);
        setIsAuthenticated(true);
        await refreshToken(); // 初回ロード時にトークンをリフレッシュ
      } else {
        setUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem('token');
      }
    });

    return () => unsubscribe();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    try {
      console.log('ログイン試行:', email); // デバッグ用
      // Firebase認証を使用
      const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('ログイン成功:', userCredential.user); // デバッグ用
      setUser(userCredential.user);
      setIsAuthenticated(true);
      const token = await userCredential.user.getIdToken();
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } catch (error) {
      console.error('ログインエラー:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsAuthenticated(false);
      localStorage.removeItem('token');
    } catch (error) {
      console.error('ログアウトエラー:', error);
      throw error;
    }
  };

  const refreshToken = async (): Promise<string | null> => {
    if (user) {
      try {
        const token = await user.getIdToken(true);
        localStorage.setItem('token', token);
        return token;
      } catch (error) {
        console.error('トークンのリフレッシュに失敗しました:', error);
        await logout(); // リフレッシュに失敗した場合はログアウト
        return null;
      }
    }
    return null;
  };

  // Axiosのインターセプターを設定
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401 && isAuthenticated) {
          // 401エラーが発生し、認証済みの場合はトークンをリフレッシュ
          const newToken = await refreshToken();
          if (newToken) {
            // リフレッシュに成功した場合、元のリクエストを再試行
            error.config.headers['Authorization'] = `Bearer ${newToken}`;
            return axios.request(error.config);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      // クリーンアップ時にインターセプターを削除
      axios.interceptors.response.eject(interceptor);
    };
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};