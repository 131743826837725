export const loadSquareSdk = () => { 
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://web.squarecdn.com/v1/square.js';
    script.onload = () => {
      if (window.Square) {
        resolve(window.Square);
      } else {
        reject(new Error('Square SDK が正しく読み込まれていません'));
      }
    };
    script.onerror = (error) => {
      console.error('Square SDK の読み込みに失敗しました:', error);
      reject(new Error('Square SDK の読み込みに失敗しました'));
    };
    document.body.appendChild(script);
  });
};
