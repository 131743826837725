import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBzxTfvXCTOGEoRsunUZfSO1R4gET5dIBM",
  authDomain: "azurechat-89118.firebaseapp.com",
  projectId: "azurechat-89118",
  storageBucket: "azurechat-89118.appspot.com",
  messagingSenderId: "185619808678",
  appId: "1:185619808678:web:6a6098337dafc6260b4f67",
  measurementId: "G-1XXTHCYCPW"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);