import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth, AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import { loadSquareSdk } from './squareUtils';
import './App.css';

import Chat from './components/Chat'; 
import { Toaster } from "./components/ui/toaster";
import { ToastProvider } from "./components/ui/use-toast";
import { config } from './config';

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleLogin = async () => {
        if (!validateEmail(email)) {
            setError('無効なメールアドレスです');
            return;
        }

        try {
            await login(email, password);
            // ログイン成功後の処理
            navigate('/chat');
        } catch (error) {
            console.error('ログインに失敗しました', error);
            setError('ログインに失敗しました。メールアドレスとパスワードを確認してください。');
        }
    };

    return (
        <div className="container">
            <h1 className="title">CONFIDENTIAL AI Chat</h1>

            <h2>ログイン</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="form-group">
                <label htmlFor="email">メールアドレス</label>
                <input type="email" id="email" placeholder="example@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="password">パスワード</label>
                <input type="password" id="password" placeholder="パスワードを入力" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <button onClick={handleLogin}>ログイン</button>
            <div className="mt-4 text-center">
                <Link to="/register" className="text-blue-500 hover:underline">新規登録はこちら</Link>
            </div>
            <div className="mt-2 text-center">
                <Link to="/unsubscribe" className="text-red-500 hover:underline">退会はこちら</Link>
            </div>
            <div className="mt-4 text-center">
            <p>
                パスワードをお忘れの方は
                <Link to="/reset-password" className="text-blue-500 hover:underline">こちら</Link>
            </p>
            </div>
        </div>
    );
};

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [card, setCard] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const initializeSquare = async () => {
            if (isInitialized || card) {
                return;
            }

            try {
                setIsInitialized(true);
                console.log('Square SDKを読み込み中...');
                const Square = await loadSquareSdk();
                console.log('Square SDKが読み込まれました:', Square);

                const payments = Square.payments(config.squareApplicationId, config.squareLocationId);
                console.log('Paymentsインスタンスが作成されました:', payments);

                const cardInstance = await payments.card();
                console.log('カードインスタンスが作成されました:', cardInstance);

                await cardInstance.attach('#card-container');
                console.log('カードインスタンスが#card-containerに接続されました');

                setCard(cardInstance);
            } catch (e) {
                console.error('Square SDKの読み込みまたはカードインスタンスの接続に失敗しました', e);
                setIsInitialized(false);
            }
        };

        initializeSquare();

        return () => {
            if (card) {
                card.destroy();
                console.log('カードインスタンスが破棄されました');
            }
        };
    }, [isInitialized, card]);

    const handleRegister = async () => {
        try {
            if (!card) {
                console.error('Card instance not initialized');
                return;
            }

            const result = await card.tokenize();
            if (result.status === 'OK') {
                // カードのトークン化に成功
                console.log('Card tokenized!');

                const response = await axios.post(`${config.apiUrl}/register`, {
                    email,
                    password,
                    cardNonce: result.token
                });
                console.log('Registration successful', response.data);
                navigate('/chat');
            } else {
                console.error('Tokenization failed');
            }
        } catch (error) {
            console.error('Registration failed', error);
        }
    };

    return (
        <div className="container">
            <h2>新登録</h2>
            <div className="form-group">
                <label htmlFor="email">メールアドレス</label>
                <input type="email" id="email" placeholder="example@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="password">パスワード（６文字以上）</label>
                <input type="password" id="password" placeholder="パスワードを入力" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className="form-group">
                <label>クレジットカード情報</label>
                <div id="card-container" style={{ minHeight: '100px', border: '1px solid #ccc' }}></div>
            </div>
            <button onClick={handleRegister}>登録</button>
        </div>
    );
};


const Unsubscribe = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleUnsubscribe = async () => {
        try {
            const response = await axios.post(`${config.apiUrl}/unsubscribe`, {
                email,
                password
            });

            if (response.data.message === 'Unsubscribe successful') {
                setMessage('退会処理が完了しました。しばらくしてから精算後、登録データを全削除します。ご利用ありがとうございました。');
                setTimeout(() => navigate('/'), 10000);
            }
        } catch (error) {
            console.error('退会処理に失敗しました', error);
            setMessage(error.response?.data?.detail || '退会処理に失敗しました。もう一度お試しください。');
        }
    };

    return (
        <div className="container">
            <h2>退会</h2>
            {message && <div className={message.includes('完了') ? 'success-message' : 'error-message'}>{message}</div>}
            <div className="form-group">
                <label htmlFor="email">メールアドス</label>
                <input type="email" id="email" placeholder="example@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="password">パスワード</label>
                <input type="password" id="password" placeholder="パスワードを入力" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <button onClick={handleUnsubscribe}>退会する</button>
        </div>
    );
};


const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleResetPassword = async (e) => {
        e.preventDefault();
        const auth = getAuth();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('パスワードリセットのメールを���信しました。メー���をご確認ください。');
            setTimeout(() => navigate('/'), 5000); // 5秒後にログイン画面に戻る
        } catch (error) {
            setMessage('エラーが発生しました: ' + error.message);
        }
    };

    return (
        <div className="container">
            <h2>パスワードリセット</h2>
            {message && <div className={message.includes('送信しました') ? 'success-message' : 'error-message'}>{message}</div>}
            <form onSubmit={handleResetPassword}>
                <div className="form-group">
                    <label htmlFor="email">メールアドレス</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="example@example.com"
                        required
                    />
                </div>
                <button type="submit">リセットメールを送信</button>
            </form>
        </div>
    );
};


const App = () => {
    useEffect(() => {
        //const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        //const host = window.location.hostname;
        
        //const port = '8000'; // バックエンドのポート番号
        //const port = '80'; // バックエンドのポート番号
        
        //const port = process.env.NODE_ENV === 'production' ? window.location.port : '8000';
        const wsUrl = `wss://confchatbacktest.azurewebsites.net/ws`;
        const socket = new WebSocket(wsUrl);

        socket.onopen = () => {
            console.log('WebSocket接続が確立されました');
        };

        socket.onmessage = (event) => {
            console.log('メッセージを受信しました:', event.data);
        };

        socket.onerror = (error) => {
            console.error('WebSocketエラー:', error);
        };

        return () => {
            if (socket.readyState === WebSocket.OPEN) {
                socket.close();
            }
        };
    }, []);

    return (
        <AuthProvider>
            <ToastProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/unsubscribe" element={<Unsubscribe />} />
                        <Route path="/reset-password" element={<ResetPassword />} /> {/* 新しいルートを追加 */}
                        <Route element={<ProtectedRoute />}>
                            <Route path="/chat" element={<Chat />} />
                        </Route>
                    </Routes>
                    <Toaster />
                </Router>
            </ToastProvider>
        </AuthProvider>
    );
};

export default App;
