import React, { useState, useRef, useEffect } from 'react'
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { Textarea } from "./ui/textarea"
import { ScrollArea, ScrollBar } from "./ui/scroll-area"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./ui/accordion"
import { PlusCircle, Send, Upload, Copy, Check, Trash2, Plus, ExternalLink, Maximize2, Minimize2, X, Code, Loader2 } from 'lucide-react'
import { Toaster } from "./ui/toaster";
import { useToast } from "./ui/use-toast"



import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select"

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog"
import axios from 'axios'
import ReactMarkdown from 'react-markdown'
import mermaid from 'mermaid'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { config } from '../config';

interface Prompt {
  id: string;
  text: string;
  category: string;
  llm: string; // 追加: 使用するLLMを指定
}

interface Category {
  id: string;
  name: string;
}

interface ChatMessage {
  id: string
  text: string
  sender: 'user' | 'ai'
  isEditing: boolean
  originalText: string
}

// Mermaidダイアログコンポーネント
const MermaidDialog: React.FC<{ isOpen: boolean; onClose: () => void; chart: string }> = ({ isOpen, onClose, chart }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { addToast } = useToast()
  const [error, setError] = useState<string | null>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const isValidMermaidSyntax = async (chart: string): Promise<boolean> => {
    try {
      await mermaid.parse(chart);
      return true;
    } catch (error) {
      console.error('Mermaid構文エラー:', error);
      return false;
    }
  };

  const renderMermaidDiagram = async (chart: string) => {
    try {
      mermaid.initialize({
        startOnLoad: false, // 修正: startOnLoadをfalseに設定
        theme: 'default',
        fontFamily: 'Arial, "Noto Sans JP", sans-serif', // 日本語対応フォントを指定
        // 以下の行を追加
        themeVariables: {
          fontFamily: 'Arial, "Noto Sans JP", sans-serif',
        },
        securityLevel: 'loose', // テキストの表示を許可
        flowchart: {
          useMaxWidth: true,
          htmlLabels: true,
          curve: 'basis'
        }
      });
      const { svg } = await mermaid.render('mermaid-diagram', chart);
      if (ref.current) {
        ref.current.innerHTML = svg;
        // SVG内のテキスト要素にフォントファミリーを適用
        const textElements = ref.current.querySelectorAll('text');
        textElements.forEach(el => {
          el.style.fontFamily = 'Arial, "Noto Sans JP", sans-serif';
        });
      }
    } catch (error) {
      console.error('Mermaid図の描画に失敗しました:', error);
      setError('Mermaid図の描画に失敗しました。構文を確認してください。');
      if (ref.current) {
        ref.current.innerHTML = `<pre>${escapeHtml(chart)}</pre>`;
      }
    }
  };

  useEffect(() => {
    const renderChart = async () => {
      if (isOpen && ref.current) {
        const isValid = await isValidMermaidSyntax(chart);
        if (isValid) {
          await renderMermaidDiagram(chart);
        } else {
          setError('無効なMermaid構文です。テキストとして表示します。');
          if (ref.current) {
            ref.current.innerHTML = `<pre>${escapeHtml(chart)}</pre>`;
          }
        }
      }
    };

    renderChart();
  }, [isOpen, chart]);

  useEffect(() => {
    if (error) {
      addToast({
        title: "Mermaid図エラー",
        description: error,
        variant: "destructive"
      });
    }
  }, [error, addToast]);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  const handleClose = () => {
    if (isFullscreen && document.exitFullscreen) {
      document.exitFullscreen();
    }
    setIsFullscreen(false);
    onClose();
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="mermaid-dialog-overlay">
      <div className="mermaid-dialog-content">
        <div className="mermaid-dialog-header">
          <h2 className="mermaid-dialog-title">Mermaid図</h2>
          <div className="mermaid-dialog-controls">
            <button onClick={toggleFullscreen} className="mermaid-dialog-button" title="全画面表示">
              {isFullscreen ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
            </button>
            <button onClick={handleClose} className="mermaid-dialog-button" title="閉じる">
              <X size={16} />
            </button>
          </div>
        </div>
        <div ref={ref} className="mermaid-diagram-container" />
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
    </div>
  );
};

// HTML特殊文字をエスケープする関数
const escapeHtml = (unsafe: string) => {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

const MermaidCodeDialog: React.FC<{ isOpen: boolean; onClose: () => void; code: string }> = ({ isOpen, onClose, code }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Mermaidコード</DialogTitle>
        </DialogHeader>
        <pre className="bg-gray-100 p-4 rounded-md overflow-auto max-h-[60vh]">
          <code>{code}</code>
        </pre>
      </DialogContent>
    </Dialog>
  );
};

export default function AIChat() {
  const [prompts, setPrompts] = useState<Prompt[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [newCategoryName, setNewCategoryName] = useState('')
  const [currentPrompt, setCurrentPrompt] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('Uncategorized')
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([])
  const [copiedId, setCopiedId] = useState<string | null>(null)
  const [, setSavingMessageId] = useState<string | null>(null)
  const { addToast } = useToast()
  const [isLoading, setIsLoading] = useState(true)
  const [shouldScroll, setShouldScroll] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [selectedLLM, setSelectedLLM] = useState<string>('azure'); // デフォルトをAzureに設定

  const scrollAreaRef = useRef<HTMLDivElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const navigate = useNavigate();
  const { logout } = useAuth();

  const [mermaidDialogOpen, setMermaidDialogOpen] = useState(false);
  const [currentMermaidChart, setCurrentMermaidChart] = useState('');
  const [mermaidCodeDialogOpen, setMermaidCodeDialogOpen] = useState(false);
  const [currentMermaidCode, setCurrentMermaidCode] = useState('');


  const [textareaRef, setTextareaRef] = useState<HTMLTextAreaElement | null>(null);

  // ローディング状態を管理する新しい状を追加
  const [isMessageSending, setIsMessageSending] = useState(false);

  // 新規プロンプト用の状態
  const [newPromptText, setNewPromptText] = useState('');
  
  // 編集中のプロンプト用の状態
  const [editingPromptId, setEditingPromptId] = useState<string | null>(null);
  const [editingPromptText, setEditingPromptText] = useState('');

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Stored token:', token); // デバッグ用
      const response = await axios.get(`${config.apiUrl}/api/user_data`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });
      console.log('Received data:', response.data); // デバッグ用
      setPrompts(response.data.prompts);
      setCategories(response.data.categories);
    } catch (error) {
      console.error('ユーザーデータの取得に失敗しました:', error);
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        handleLogout();
      }
    } finally {
      setIsLoading(false);
    }
  };

  // コンポーネントがマウントされたときにユーザーデータを取得
  useEffect(() => {
    fetchUserData();
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      const scrollElement = scrollAreaRef.current.querySelector('[data-radix-scroll-area-viewport]')
      if (scrollElement) {
        scrollElement.scrollTop = scrollElement.scrollHeight
      }
    }
  }

  // チャットメッセージが更新されたときにスクロールする
  useEffect(() => {
    if (shouldScroll) {
      scrollToBottom()
      setShouldScroll(false)
    }
  }, [chatMessages, shouldScroll])

  const handlePromptClick = (promptText: string) => {
    if (textareaRef) {
      const start = textareaRef.selectionStart;
      const end = textareaRef.selectionEnd;
      const currentText = currentPrompt;
      const newText = currentText.substring(0, start) + promptText + currentText.substring(end);
      setCurrentPrompt(newText);
      
      // カーソルを挿入したテキストの後ろに移動
      setTimeout(() => {
        textareaRef.selectionStart = textareaRef.selectionEnd = start + promptText.length;
        textareaRef.focus();
      }, 0);
    }
  }

  const handleSendMessage = async () => {
    if (currentPrompt.trim()) {
      setIsMessageSending(true);
      const newUserMessage: ChatMessage = {
        id: Date.now().toString(),
        text: currentPrompt,
        sender: 'user',
        isEditing: false,
        originalText: currentPrompt
      }
      setChatMessages(prevMessages => [...prevMessages, newUserMessage])
      setCurrentPrompt('')

      // ユーザーのメッセージが追加された後にスクロール
      setTimeout(scrollToLatestMessage, 0);

      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${config.apiUrl}/api/chat`, 
          { prompt: currentPrompt, selected_llm: selectedLLM },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        console.log("Response data:", response.data);
        const aiResponse: ChatMessage = {
          id: (Date.now() + 1).toString(),
          text: response.data.message,
          sender: 'ai',
          isEditing: false,
          originalText: response.data.message
        }
        setChatMessages(prevMessages => [...prevMessages, aiResponse])
        
        // AIの応答が追加された後にスクロール
        setTimeout(scrollToLatestMessage, 100);

        // トークン情報をトースト表示
        if (response.data.token_info) {
          addToast({
            title: "トークン使用情報",
            description: `総トークン数: ${response.data.token_info.total_tokens}\n費用: ${response.data.token_info.cost}JPY`,
            variant: "info",
            duration: 5000
          })
        }
      } catch (error) {
        console.error('メッセージの送信中にエラーが発生しました:', error);
        addToast({
          title: "エラー",
          description: "メッセージの送信に失敗しました。",
          variant: "destructive"
        })
      } finally {
        setIsMessageSending(false);
      }
    }
  }


  const handleCopy = (text: string, id: string) => {
    const textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()
    
    try {
      document.execCommand('copy')
      setCopiedId(id)
      addToast({ title: "Copied to clipboard", description: "The message has been copied to your clipboard." })
      setTimeout(() => setCopiedId(null), 2000)
    } catch (err) {
      console.error('Failed to copy text: ', err)
      addToast({ title: "Copy failed", description: "Failed to copy the text. Please try selecting and copying manually.", variant: "destructive" })
    } finally {
      document.body.removeChild(textArea)
    }
  }

  const handleEditMessage = (id: string, newText: string) => {
    setChatMessages(prevMessages =>
      prevMessages.map(message =>
        message.id === id ? { ...message, text: newText } : message
      )
    )
  }

  const toggleEditMode = (id: string) => {
    setChatMessages(prevMessages =>
      prevMessages.map(message =>
        message.id === id
          ? { ...message, isEditing: !message.isEditing, originalText: message.text }
          : message
      )
    )
  }

  const handleCancelEdit = (id: string) => {
    setChatMessages(prevMessages =>
      prevMessages.map(message =>
        message.id === id
          ? { ...message, isEditing: false, text: message.originalText }
          : message
      )
    )
  }

  const handleDeletePrompt = async (id: string) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${config.apiUrl}/api/delete_prompt/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setPrompts(prevPrompts => prevPrompts.filter(prompt => prompt.id !== id));
      addToast({ title: "プロンプトが削除されました" });
    } catch (error) {
      console.error('プロンプトの削除に失敗しました:', error);
      addToast({ title: "エラー", description: "プロンプトの削除に失敗しました。", variant: "destructive" });
    }
  }

  const handleAddCategory = async () => {
    if (newCategoryName.trim()) {
      if (categories.length >= config.maxCategories) {
        addToast({ title: "エラー", description: `カテゴリの最大登録数（${config.maxCategories}）に達しました。`, variant: "destructive" });
        return;
      }
      try {
        const token = localStorage.getItem('token');
        const data = { name: newCategoryName.trim() };
        console.log('Sending data:', data);  // デバッグ用ログ
        const response = await axios.post(`${config.apiUrl}/api/add_category`, 
          data,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        console.log('Response:', response.data);  // デバッグ用ログ
        setCategories([...categories, response.data.category]);
        setNewCategoryName('');
        addToast({ title: "カテゴリが追加されました" });
      } catch (error) {
        console.error('カテゴリの追加に失敗しました:', error);
        if (axios.isAxiosError(error) && error.response) {
          console.error('エラーレスポンス:', error.response.data);  // デバッグ用ログ
        }
        addToast({ title: "エラー", description: "カテゴリの追加に失敗しました。", variant: "destructive" });
      }
    }
  }

  const handleDeleteCategory = async (id: string) => {
    // 削除するカテゴリを取得
    const category = categories.find(c => c.id === id);
    if (!category) {
      addToast({ title: "エラー", description: "カテゴリが見つかりません。", variant: "destructive" });
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${config.apiUrl}/api/delete_category/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCategories(prevCategories => prevCategories.filter(category => category.id !== id));
      // カテゴリ削除前に取得したカテゴリ名を使用
      setPrompts(prevPrompts => prevPrompts.map(prompt => 
        prompt.category === category.name
          ? { ...prompt, category: 'その他' }
          : prompt
      ));
      addToast({ title: "カテゴリが削除されました" });
    } catch (error) {
      console.error('カテゴリの削除に失敗しました:', error);
      addToast({ title: "エラー", description: "カテゴリの削除に失敗しました。", variant: "destructive" });
    }
  }

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) {
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await axios.post(`${config.apiUrl}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      
      if (textareaRef) {
        const start = textareaRef.selectionStart;
        const end = textareaRef.selectionEnd;
        const currentText = currentPrompt;
        const newText = currentText.substring(0, start) + response.data.text + currentText.substring(end);
        setCurrentPrompt(newText);
        
        // カーソルを挿入したテキストの後ろに移動
        setTimeout(() => {
          textareaRef.selectionStart = textareaRef.selectionEnd = start + response.data.text.length;
          textareaRef.focus();
        }, 0);
      }
    } catch (error) {
      console.error('File upload error:', error)
      addToast({ 
        title: "エラー", 
        description: "ファイルのアップロードに失敗しました。", 
        variant: "destructive" 
      })
    }
  }

  const handleClearChat = async () => {
    try {
      // バックエンドのチャット履歴クリアエンドポイントを呼び出す
      await axios.post(`${config.apiUrl}/api/clear-chat`)
      
      // フロントエンドの状態をクリア
      setChatMessages([])
      setCurrentPrompt('')
      
      addToast({ 
        title: "成功", 
        description: "チャット履歴がクリアさ���ました。", 
        variant: "default" 
      })
    } catch (error) {
      console.error('チャット履歴のクリアに失敗しました:', error)
      addToast({ 
        title: "エラー", 
        description: "チャット履歴のクリアに失敗しました。", 
        variant: "destructive" 
      })
    }
  }

  const handleSavePrompt = async (text?: string) => {
    const promptText = text ?? currentPrompt.trim();
    if (promptText) {
      if (prompts.length >= config.maxPrompts) {
        addToast({ title: "エラー", description: `プロンプトの最大登録数（${config.maxPrompts}）に達しました。`, variant: "destructive" });
        return;
      }
      try {
        const token = localStorage.getItem('token');
        const data = {
          text: promptText,
          category: selectedCategory
        };
        console.log('Sending prompt:', data);
        const response = await axios.post(`${config.apiUrl}/api/save_prompt`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log('Prompt saved:', response.data);
        setPrompts([...prompts, response.data.prompt]);
        if (!text) {
          setCurrentPrompt('');
        }
        addToast({ title: "プロンプトが保存されました" });
        setIsDialogOpen(false); // ダイアログを閉じる
      } catch (error) {
        console.error('プロンプトの保存に失敗しました:', error);
        if (axios.isAxiosError(error) && error.response) {
          console.error('エラーレスポンス:', error.response.data);
        }
        addToast({ title: "エラー", description: "プロンプトの送信に失敗しました。", variant: "destructive" });
      }
    }
  }

  const handleOpenMermaidDialog = (chart: string) => {
    setCurrentMermaidChart(chart);
    setMermaidDialogOpen(true);
  };

  const handleOpenMermaidCodeDialog = (code: string) => {
    setCurrentMermaidCode(code);
    setMermaidCodeDialogOpen(true);
  };

  const renderMessage = (message: ChatMessage) => {
    const mermaidRegex = /```mermaid\n([\s\S]*?)```/g;
    const parts: React.ReactNode[] = [];
    let lastIndex = 0;
    let match: RegExpExecArray | null;

    while ((match = mermaidRegex.exec(message.text)) !== null) {
      if (match.index > lastIndex) {
        parts.push(
          <ReactMarkdown key={lastIndex} className="mb-2 whitespace-pre-wrap">
            {message.text.slice(lastIndex, match.index)}
          </ReactMarkdown>
        );
      }
      const mermaidContent = match[1];
      parts.push(
        <div key={match.index} className="flex flex-col space-y-2 mb-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => handleOpenMermaidDialog(mermaidContent)}
          >
            <ExternalLink className="h-4 w-4 mr-2" />
            Mermaid図を表示
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => handleOpenMermaidCodeDialog(mermaidContent)}
          >
            <Code className="h-4 w-4 mr-2" />
            Mermaidコードを表示
          </Button>
        </div>
      );
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < message.text.length) {
      parts.push(
        <ReactMarkdown key={lastIndex} className="mb-2 whitespace-pre-wrap">
          {message.text.slice(lastIndex)}
        </ReactMarkdown>
      );
    }

    return parts;
  };

  // プロンプトの編集を開始する関数
  const startEditingPrompt = (promptId: string, promptText: string) => {
    setEditingPromptId(promptId);
    setEditingPromptText(promptText);
  };

  // プロンプトの編集を保存する関数
  const saveEditedPrompt = async (promptId: string) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${config.apiUrl}/api/update_prompt/${promptId}`, 
        { text: editingPromptText },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setPrompts(prevPrompts => prevPrompts.map(prompt => 
        prompt.id === promptId ? { ...prompt, text: editingPromptText } : prompt
      ));
      setEditingPromptId(null);
      setEditingPromptText('');
      addToast({ title: "プロンプトが更新されました" });
    } catch (error) {
      console.error('プロンプトの更新に失敗しました:', error);
      addToast({ title: "エラー", description: "プロンプトの更新に失敗しました。", variant: "destructive" });
    }
  };

  // 新規プロンプトを追加する関数
  const addNewPrompt = async (categoryName: string) => {
    if (newPromptText.trim()) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${config.apiUrl}/api/save_prompt`, 
          { text: newPromptText, category: categoryName },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        setPrompts(prevPrompts => [...prevPrompts, response.data.prompt]);
        setNewPromptText('');
        addToast({ title: "新しいプロンプトが追加されました" });
      } catch (error) {
        console.error('新規プロンプトの追加に失敗しました:', error);
        addToast({ title: "エラー", description: "新規プロンプトの追加に失敗しました。", variant: "destructive" });
      }
    }
  };

  // スクロール関数を更新
  const scrollToLatestMessage = () => {
    if (scrollAreaRef.current) {
      const scrollElement = scrollAreaRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-screen">
      <div className="w-1/3 p-4 border-r flex flex-col">
        <h2 className="text-2xl font-bold mb-4">Prompt List</h2>
        <div className="mb-4 flex items-center space-x-2">
          <Input
            type="text"
            placeholder="New category name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
          <Button onClick={handleAddCategory} size="sm">
            <Plus className="h-4 w-4 mr-2" />
            Add
          </Button>
        </div>
        <ScrollArea className="flex-grow pr-4" style={{ height: 'calc(100vh - 200px)' }} type="always">
        <Accordion type="single" collapsible className="w-full">
            {categories.map((category) => (
              <AccordionItem value={category.id} key={category.id}>
                <AccordionTrigger className="flex justify-between items-center">
                  <span>{category.name}</span>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (category.name !== 'その他' && category.id) {
                        handleDeleteCategory(category.id);
                      }
                    }}
                    className={`h-6 w-6 p-0 ml-auto ${category.name === 'その他' ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={category.name === 'その他'}
                  >
                    <Trash2 className="h-3 w-3" />
                  </Button>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="space-y-2">
                    {prompts
                      .filter((prompt) => prompt.category === category.name)
                      .map((prompt) => (
                        <div key={prompt.id} className="border rounded p-2">
                          {editingPromptId === prompt.id ? (
                            <>
                              <Textarea
                                value={editingPromptText}
                                onChange={(e) => setEditingPromptText(e.target.value)}
                                className="mb-2"
                              />
                              <div className="flex items-center space-x-2">
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() => saveEditedPrompt(prompt.id)}
                                >
                                  保存
                                </Button>
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() => {
                                    setEditingPromptId(null);
                                    setEditingPromptText('');
                                  }}
                                >
                                  キャンセル
                                </Button>
                              </div>
                            </>
                          ) : (
                            <>
                              <p className="whitespace-pre-wrap mb-2">{prompt.text}</p>
                              <div className="flex items-center space-x-2">
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() => handlePromptClick(prompt.text)}
                                >
                                  使用する
                                </Button>
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() => startEditingPrompt(prompt.id, prompt.text)}
                                >
                                  編集
                                </Button>
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() => handleDeletePrompt(prompt.id)}
                                >
                                  <Trash2 className="h-4 w-4" />
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    <div className="mt-4">
                      <Textarea
                        value={newPromptText}
                        onChange={(e) => setNewPromptText(e.target.value)}
                        placeholder="新しいプロンプトを入力..."
                        className="mb-2"
                      />
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          addNewPrompt(category.name);
                          setNewPromptText('');
                        }}
                      >
                        新規プロンプト追加
                      </Button>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
          <ScrollBar orientation="vertical" className="scrollbar-thumb scrollbar-track" />        </ScrollArea>
      </div>

      <div className="w-2/3 p-4 flex flex-col">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-bold flex-grow text-center">CONFIDENTIAL AI Chat</h2>
          <div className="flex space-x-2">
            <Button 
              onClick={handleClearChat} 
              variant="default"
              size="sm"
              className="h-8 px-3 text-xs bg-blue-500 hover:bg-blue-600 text-white max-w-[150px] self-center flex items-center"
            >
              <Trash2 className="h-3 w-3 mr-1" />
              会話履歴を消去
            </Button>
            <Button onClick={handleLogout} size="sm">ログアウト</Button>
          </div>
        </div>
        {/* ここにスクロール可能なエリアを追加 */}
        <div 
          ref={scrollAreaRef}
          className="flex-grow mb-4 p-4 border rounded-md overflow-y-auto max-h-[calc(100vh-300px)]"
        >
          {chatMessages.map((message) => (
            <div key={message.id} className={`mb-4 ${message.sender === 'ai' ? 'pl-4 border-l-2 border-blue-500' : ''}`}>
              <div className="flex justify-between items-start">
                <p className="font-bold">{message.sender === 'user' ? 'You:' : 'AI:'}</p>
                <div className="flex space-x-2">
                  {message.sender === 'user' && (
                    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                      <DialogTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            setSavingMessageId(message.id)
                            setIsDialogOpen(true)
                          }}
                        >
                          <PlusCircle className="h-4 w-4 mr-2" />
                          Save
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                        <DialogHeader>
                          <DialogTitle className="text-xl font-bold mb-4 text-black dark:text-white">Save Prompt</DialogTitle>
                        </DialogHeader>
                        <div className="space-y-4">
                          <Select value={selectedCategory} onValueChange={setSelectedCategory}>
                            <SelectTrigger className="w-full bg-white dark:bg-gray-700 text-black dark:text-white">
                              <SelectValue placeholder="Select category" />
                            </SelectTrigger>
                            <SelectContent className="bg-white dark:bg-gray-700">
                              {categories.map((category) => (
                                <SelectItem key={category.id} value={category.name} className="text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600">
                                  {category.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <Button onClick={() => handleSavePrompt(message.text)} className="w-full">
                            Save Prompt
                          </Button>
                        </div>
                      </DialogContent>
                    </Dialog>
                  )}
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleCopy(message.text, message.id)}
                  >
                    {copiedId === message.id ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
                    {copiedId === message.id ? 'Copied' : 'Copy'}
                  </Button>
                  {message.isEditing ? (
                    <>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => toggleEditMode(message.id)}
                      >
                        保存
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleCancelEdit(message.id)}
                      >
                        キャンセル
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => toggleEditMode(message.id)}
                    >
                      編集
                    </Button>
                  )}
                </div>
              </div>
              <div className="relative">
                {renderMessage(message)}
                {message.isEditing && (
                  <Textarea
                    value={message.text}
                    onChange={(e) => handleEditMessage(message.id, e.target.value)}
                    className="w-full mt-2 min-h-[300px] absolute top-0 left-0 z-10"
                  />
                )}
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="flex items-center justify-center">
              <span className="loading loading-dots loading-md"></span>
            </div>
          )}
        </div>
        {/* 入力エリアは変更なし */}
        <div className="flex items-end space-x-2">
          <Textarea
            value={currentPrompt}
            onChange={(e) => setCurrentPrompt(e.target.value)}
            placeholder="Type your message here..."
            className="flex-grow min-h-[100px] resize-none"
            disabled={isMessageSending}
            ref={(ref) => setTextareaRef(ref)}
          />
          <div className="flex flex-col space-y-2">
            <Select
              value={selectedLLM}
              onValueChange={(value) => setSelectedLLM(value)}
            >
              <SelectTrigger className="w-[120px]">
                <SelectValue placeholder="LLMを選択" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="azure">Azure ChatGPT</SelectItem>
                <SelectItem value="gemini">Google Gemini-1.5</SelectItem>
              </SelectContent>
            </Select>
            <Button 
              onClick={handleSendMessage} 
              disabled={isMessageSending} 
              size="icon"
              className="h-10 w-10 rounded-full flex-shrink-0"
            >
              {isMessageSending ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Send className="h-4 w-4" />
              )}
            </Button>
          </div>
        </div>
        <div className="mt-4">
          <input
            type="file"
            id="file-upload"
            className="sr-only"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept=".pdf,.docx,.txt,.md"
          />
          <label
            htmlFor="file-upload"
            className="cursor-pointer inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
          >
            <Upload className="mr-2 h-4 w-4" /> Upload File
          </label>
        </div>
      </div>
      <MermaidDialog
        isOpen={mermaidDialogOpen}
        onClose={() => setMermaidDialogOpen(false)}
        chart={currentMermaidChart}
      />
      <MermaidCodeDialog
        isOpen={mermaidCodeDialogOpen}
        onClose={() => setMermaidCodeDialogOpen(false)}
        code={currentMermaidCode}
      />
      <Toaster />
    </div>
  )
}
