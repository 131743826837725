import React from 'react';
import { useToast } from './use-toast';

export const Toaster: React.FC = () => {
  const { toasts, removeToast } = useToast();

  return (
    <div className="toaster-container">
      {toasts.map((toast) => (
        <div key={toast.id} className={`toast-item ${toast.variant}`}>
          <div className="toast-title">{toast.title}</div>
          <div className="toast-content">
            {toast.description && <p>{toast.description}</p>}
          </div>
          <button onClick={() => removeToast(toast.id)} className="toast-close-btn">
            ×
          </button>
        </div>
      ))}
    </div>
  );
};