import React, { useState, useCallback, createContext, useContext, ReactNode } from 'react'

export interface Toast {
  id: string
  title: string
  description?: string
  duration?: number
  variant?: string
}

interface ToastOptions {
  title: string
  description?: string
  duration?: number
  variant?: string
}

interface ToastContextValue {
  toasts: Toast[]
  addToast: (toast: ToastOptions) => void
  removeToast: (id: string) => void
}

const ToastContext = createContext<ToastContextValue | undefined>(undefined)

interface ToastProviderProps {
  children: ReactNode
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const addToast = useCallback(({ title, description = '', duration = 5000, variant = 'default' }: ToastOptions) => {
    const id = Math.random().toString(36).substr(2, 9)
    const newToast: Toast = { id, title, description, duration, variant }
    
    setToasts((currentToasts) => [...currentToasts, newToast])

    if (duration > 0) {
      setTimeout(() => {
        removeToast(id)
      }, duration)
    }
  }, [])

  const removeToast = useCallback((id: string) => {
    setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

export type UseToastReturn = ReturnType<typeof useToast>