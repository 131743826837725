console.log('REACT_APP_SQUARE_APPLICATION_ID:', process.env.REACT_APP_SQUARE_APPLICATION_ID);
console.log('REACT_APP_SQUARE_LOCATION_ID:', process.env.REACT_APP_SQUARE_LOCATION_ID);

export const config = {
    //apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:8000',
    apiUrl: process.env.REACT_APP_API_URL || 'https://confchatbacktest.azurewebsites.net',
    maxPrompts: parseInt(process.env.REACT_APP_MAX_PROMPTS || '100', 10),
    maxCategories: parseInt(process.env.REACT_APP_MAX_CATEGORIES || '10', 10),
    squareApplicationId: process.env.REACT_APP_SQUARE_APPLICATION_ID || 'sq0idp-DT5CiLN8-oA55dQRBm4YCw',
    squareLocationId: process.env.REACT_APP_SQUARE_LOCATION_ID || 'LZXX9GAAKTBA8',
};